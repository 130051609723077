import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { DataState, PasswordValid, TransferPage } from "../../../interfaces/enums";
import { GetDefaultUser, getBaseUrl } from "../../Global/GobalFunctions";
import { BaseLineup, OldTitanAccount, PvrSettings } from "../../../interfaces/settings";
import { GridSettings, CellSettings, DetailSettings } from "../../../interfaces/settings";
import { errorLog } from "../../Global/ConsoleLogging";

const baseUrl = getBaseUrl()

// #region userState
export interface userSliceInterface {
	newGuestUserStatus: DataState;
	defaultUserStatus: DataState;
	userInfoStatus: DataState;
	validateUserStatus: DataState;
	newRegisteredStatus: DataState;
	upgradeGuestUserStatus: DataState;
	deleteUserStatus: DataState;
	newUserStatus: DataState;
	passwordStatus: PasswordValid;
	emailUpdated: DataState;
	passwordUpdated: DataState;
	rememberMe: boolean;
	userId: string;
	userName: string;
	email: string;
	oldEmail: string;
	oldPassword: string;
	password: string;
	confirmPassword: string;
	isAnonymous: boolean;
	isAdmin: boolean;
	newRegUser: boolean;
	userError: string;
	userErrorCode: number;
	pageFirstLoad: boolean;
	isError: boolean;
	transferPage: TransferPage;
	oldTitanPassword: string;
	lineupTransferList: string[];
	oldAccountLoadingStatus: DataState;
	oldTitanAccount: OldTitanAccount;
	accountMigrationStatus: DataState
	passwordResetRequestStatus: DataState;
	passwordResetStatus: DataState;
	usernameRecoveryStatus: DataState;
}

const initialState: userSliceInterface = {
	newGuestUserStatus: DataState.IDLE,
	defaultUserStatus: DataState.IDLE,
	userInfoStatus: DataState.IDLE,
	validateUserStatus: DataState.IDLE,
	newRegisteredStatus: DataState.IDLE,
	upgradeGuestUserStatus: DataState.IDLE,
	deleteUserStatus: DataState.IDLE,
	passwordStatus: PasswordValid.EMPTY,
	newUserStatus: DataState.IDLE,
	emailUpdated: DataState.IDLE,
	passwordUpdated: DataState.IDLE,
	rememberMe: false,
	userId: '',
	userName: '',
	email: '',
	oldEmail: '',
	oldPassword: '',
	password: '',
	confirmPassword: '',
	isAnonymous: true,
	isAdmin: false,
	newRegUser: false,
	userError: '',
	userErrorCode: 0,
	pageFirstLoad: true,
	isError: false,
	transferPage: TransferPage.LOGIN,
	oldTitanPassword: '',
	lineupTransferList: [],
	oldAccountLoadingStatus: DataState.IDLE,
	oldTitanAccount: {} as OldTitanAccount,
	accountMigrationStatus: DataState.IDLE,
	passwordResetRequestStatus: DataState.IDLE,
	passwordResetStatus: DataState.IDLE,
	usernameRecoveryStatus: DataState.IDLE
}

export interface newUserSettings {
	postalCode: string;
	defaultLineupId: string;
	gridSettings: GridSettings;
	cellSettings: CellSettings;
	detailSettings: DetailSettings;
	pvrSettings: PvrSettings;
}
// #endregion

export const fetchNewGuestUser = createAsyncThunk('user/fetchNewGuestUser', async (arg: { settings: newUserSettings, lineup: BaseLineup, setToPrimeTime: boolean }) => {
	return fetch(baseUrl + 'user/guest')
		.then(res => res.json())
		.then(data => {
			const userId = data.userId
			const lineupUrl = `${baseUrl}lineup/${userId}`
			const reqData = {
				lineupName: arg.lineup.providerName,
				utcOffset: arg.lineup.utcOffset,
				observesDST: arg.lineup.observesDst,
				providerId: arg.lineup.providerId,
				deviceTypeCode: arg.lineup.deviceTypeCode
			}
			const req = {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(reqData),
			}
			return fetch(lineupUrl, req)
				.then(res => res.json())
				.then(
					data => {
						arg.settings.defaultLineupId = data.lineupId
						arg.settings.gridSettings.fixedCellHeight = true;
						arg.settings.gridSettings.includeShowCards = false;
						arg.settings.gridSettings.gridDuration = 0;
						if (!arg.setToPrimeTime) {
							arg.settings.gridSettings.gridStartHour = -1
						}
						const settingsUrl = `${baseUrl}preference/${userId}`;
						const req = {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify(arg.settings),
						}
						return fetch(settingsUrl, req)
							.then(res => res.json())
							.then(data => {
								localStorage.userId = userId;
								return data
							})
							.catch((err) => errorLog(`Fetch New User Error, Update Settings: ${err}`, true))
					}
				)
				.catch((err) => errorLog(`Fetch New User Error, New Lineup: ${err}`, true))
		})
		.catch((err) => errorLog(`Fetch New User Error: ${err}`, true))
});

export const fetchDefaultUser = createAsyncThunk('user/fetchDefaultUser', async () => {
	return fetch(baseUrl + 'user/default')
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Fetch Default User Error: ${err}`, true))
});

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async (userId: string) => {
	return fetch(`${baseUrl}user/${userId}`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => {
			errorLog(`Fetch User Error: ${err}`, true);
			return (
				{
					userErrorCode: 500,
					userError: 'Server Could not be reached'
				}
			)
		});
});

export const validateUser = createAsyncThunk('user/ValidateUser', async (data: { loginName: string, password: string }) => {
	const url = `${baseUrl}login`;
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Validate User Error: ${err}`, true))
});

// This api call doesn't exist
export const newRegisteredUser = createAsyncThunk('user/newRegisteredUser', async (data: { loginName: string, password: string, emailAddress: string, lineup: BaseLineup, settings: newUserSettings }) => {
	const url = `${baseUrl}user`;
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => {

		})
		.catch((err) => errorLog(`Reg New User Error: ${err}`, true))
});

export const upgradeGuestUser = createAsyncThunk('user/upgradeGuestUser', async (data: { userId: string, loginName: string, password: string, emailAddress: string }) => {
	const url = `${baseUrl}user/${data.userId}/upgrade`;
	const reqData = { loginName: data.loginName, password: data.password, emailAddress: data.emailAddress }
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Validate User Error: ${err}`, true))
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (userId: string) => {
	const url = `${baseUrl}user/${userId}`;
	const req = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json'
		},
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Reg New User Error: ${err}`, true))
});

export const updateEmail = createAsyncThunk('user/updateEmail', async (data: { userId: string, loginName: string, oldEmail: string, newEmail: string }) => {
	const url = `${baseUrl}user/${data.userId}/email`;
	const reqData = {
		loginName: data.loginName,
		oldEmail: data.oldEmail,
		newEmail: data.newEmail
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData)
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Update Email Error: ${err}`, true))
});

export const updatePassword = createAsyncThunk('user/updatePassword', async (data: { userId: string, userName: string, oldPassword: string, newPassword: string }) => {
	const url = `${baseUrl}user/${data.userId}/password`;
	const reqData = {
		loginName: data.userName,
		oldPassword: data.oldPassword,
		newPassword: data.newPassword
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData)
	}
	alert('Password updated');
	return fetch(url, req)
		.then(res => res.json())
		.then(data => {
			return data
		})
		.catch((err) => errorLog(`Update Password Error: ${err}`, true))
});

export const postLegacyTitanTVAccount = createAsyncThunk('user/getLegacyTitanTVAccount', async (data: { userId: string, loginName: string, password: string }) => {
	const url = `${baseUrl}migration/${data.userId}/check`
	const reqData = {
		loginName: data.loginName,
		password: data.password
	}

	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Migration Check Error: ${err}`, true))
})

export const postLegacyTitanTVMigrate = createAsyncThunk('user/postLegacyTitanTVMigrate', async (data: { userId: string, legacyUserId: string, newPassword: string }) => {
	const url = `${baseUrl}migration/${data.userId}/migrate`
	const reqData = {
		legacyUserId: data.legacyUserId,
		newPassword: data.newPassword
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data; })
		.catch((err) => errorLog(`Migration Error: ${err}`, true))
});

export const postUsernameRecovery = createAsyncThunk('user/postUsernameRecovery', async (email: string) => {
	const url = `${baseUrl}user/login/retrieve`
	const reqData = {
		email: email
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData)
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Username Recovery Request: ${err}`, true))
});

export const postPasswordResetRequest = createAsyncThunk('user/postPasswordResetRequest', async (email: string) => {
	const url = `${baseUrl}user/password/requestReset`
	const reqData = {
		email: email
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Password Reset Request: ${err}`, true))
});

export const postPasswordReset = createAsyncThunk('user/passwordReset', async (data: { email: string, passwordResetToken: string, password: string }) => {
	const url = `${baseUrl}user/password/reset`
	const reqData = data
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Password Reset: ${err}`, true))
});

export const userSlice = createSlice({
	name: 'userSlice',
	initialState,
	reducers: {
		setUserId: (state, action) => {
			if (localStorage.rememberMe === `false`) {
				localStorage.userId = ''
			}
			sessionStorage.userId = action.payload;
			state.userId = action.payload;
		},
		setUsername: (state, action) => {
			state.userName = action.payload
		},
		setEmail: (state, action) => {
			state.email = action.payload
		},
		setOldPassword: (state, action) => {
			state.oldPassword = action.payload;
		},
		setPassword: (state, action) => {
			state.password = action.payload
		},
		setConfirmPassword: (state, action) => {
			state.confirmPassword = action.payload
		},
		setIsAnonymous: (state, action) => {
			state.isAnonymous = action.payload
		},
		setNewUserStatus: (state, action) => {
			state.newUserStatus = action.payload
		},
		setPasswordStatus: (state, action) => {
			state.passwordStatus = action.payload
		},
		setRemeberMe: (state) => {
			state.rememberMe = !state.rememberMe;
		},
		setNewRegUser: (state, action) => {
			state.newRegUser = action.payload
		},
		setEmailUpdated: (state, action) => {
			state.emailUpdated = action.payload;
		},
		setPasswordUpdated: (state, action) => {
			state.passwordUpdated = action.payload;
		},
		setPageFirstLoad: (state, action) => {
			state.pageFirstLoad = action.payload
		},
		setNewGuestUserStatus: (state, action) => {
			state.newGuestUserStatus = action.payload
		},
		setTransferPage: (state, action) => {
			state.transferPage = action.payload
		},
		setOldTitanPassword: (state, action) => {
			state.oldTitanPassword = action.payload
		},
		setAddLineupTransferList: (state, action) => {
			state.lineupTransferList.push(action.payload);
		},
		setRemoveLineupTransferList: (state, action) => {
			state.lineupTransferList = state.lineupTransferList.filter((l) => {
				return (l !== action.payload)
			})
		},
		setLineupSelectionEmpty: (state, action) => {
			state.lineupTransferList = [];
		},
		setPasswordResetRquestStatus: (state, action) => {
			state.passwordResetRequestStatus = action.payload
		},
		setPasswordResetStatus: (state, action) => {
			state.passwordResetStatus = action.payload
		},
		setUsernameRecoveryStatus: (state, action) => {
			state.usernameRecoveryStatus = action.payload
		},
		setAccountMigrationStatus: (state, action) => {
			state.accountMigrationStatus = action.payload;
		},
		setDeletUserStatus: (state, action) => {
			state.deleteUserStatus = action.payload;
		}

	},

	extraReducers: builder => {
		//#region fetchNewGuestUser
		builder.addCase(fetchNewGuestUser.pending, (state) => {
			state.newGuestUserStatus = DataState.LOADING;
			state.newUserStatus = DataState.LOADING;
		})
		builder.addCase(fetchNewGuestUser.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.status < 200 || action.payload.status > 299) {
				state.newGuestUserStatus = DataState.FAILED
			} else {
				state.newGuestUserStatus = DataState.SUCCEEDED
				state.userId = localStorage.userId
				window.location.reload();
			}
		})
		builder.addCase(fetchNewGuestUser.rejected, (state) => {
			state.newGuestUserStatus = DataState.FAILED
		})
		//#endregion

		//#region defaultUser
		builder.addCase(fetchDefaultUser.pending, (state) => {
			state.defaultUserStatus = DataState.LOADING
		})
		builder.addCase(fetchDefaultUser.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.defaultUserStatus = DataState.FAILED
			} else {
				state.defaultUserStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(fetchDefaultUser.rejected, (state) => {
			state.defaultUserStatus = DataState.FAILED
		})
		//#endregion

		//#region fetchUserInfo
		builder.addCase(fetchUserInfo.pending, (state) => {
			state.userInfoStatus = DataState.LOADING
		})
		builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.status < 200 || action.payload.status > 299) {
				state.userInfoStatus = DataState.FAILED
			} else {
				if (action.payload.errorCode > 0) {
					state.userInfoStatus = DataState.FAILED;
					state.userError = action.payload.errorMessage;
					state.userErrorCode = action.payload.errorCode;
				}
				if (action.payload.errorCode === -1) {
					alert('User Id not found, reloading Site');
					localStorage.removeItem('userId');
					window.location.reload();
				}
				if (!action.payload.isAnonymous) {
					state.userName = action.payload.loginName;
					state.email = action.payload.email;
					state.oldEmail = action.payload.email;
				}
				state.isAnonymous = action.payload.isAnonymous;
				state.userInfoStatus = DataState.SUCCEEDED;
				state.userError = action.payload.errorMessage
				state.userErrorCode = action.payload.errorCode
			}
		})
		builder.addCase(fetchUserInfo.rejected, (state, action) => {
			state.userInfoStatus = DataState.FAILED
			alert('Failed to fetch user info')
			//state.userError = action.payload.errorMessage;
			//state.userErrorCode = action.payload.errorCode;

		})
		//#endregion

		//#region validateUser
		builder.addCase(validateUser.pending, (state) => {
			state.validateUserStatus = DataState.LOADING
		})
		builder.addCase(validateUser.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.status < 200 || action.payload.status > 299) {
				state.validateUserStatus = DataState.FAILED
			}
			// else if (action.payload.userId === '00000000-0000-0000-0000-000000000000') {
			// 	state.userError = action.payload.errorMessage
			// 	state.userErrorCode = action.payload.errorCode
			// 	state.validateUserStatus = DataState.FAILED
			// } else 
			{
				state.validateUserStatus = DataState.SUCCEEDED
				localStorage.userId = action.payload.userId;
				localStorage.rememberMe = state.rememberMe;
				window.location.reload()
			}
		})
		builder.addCase(validateUser.rejected, (state) => {
			state.validateUserStatus = DataState.FAILED
		})
		//#endregion

		//#region newRegisteredUser
		builder.addCase(newRegisteredUser.pending, (state) => {
			state.newRegisteredStatus = DataState.LOADING
		})
		builder.addCase(newRegisteredUser.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.newRegisteredStatus = DataState.FAILED
			} else {
				state.newRegisteredStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(newRegisteredUser.rejected, (state) => {
			state.newRegisteredStatus = DataState.FAILED
		})
		//#endregion

		//#region upgradeGuestUser
		builder.addCase(upgradeGuestUser.pending, (state) => {
			state.upgradeGuestUserStatus = DataState.LOADING
		})
		builder.addCase(upgradeGuestUser.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.status < 200 || action.payload.status > 299) {
				alert('Upgrade FAILED')
				state.upgradeGuestUserStatus = DataState.FAILED
			} else {
				if (action.payload.errorCode !== 0) {
					state.userError = action.payload.errorMessage;
					state.userErrorCode = action.payload.errorCode;
					state.isError = true;
				} else {
					state.upgradeGuestUserStatus = DataState.SUCCEEDED
					alert('User Data Updated');
					window.location.reload();
				}
			}
		})
		builder.addCase(upgradeGuestUser.rejected, (state) => {
			state.upgradeGuestUserStatus = DataState.FAILED
			alert('Upgrade FAILED')
		})
		//#endregion

		//#region deleteUser
		builder.addCase(deleteUser.pending, (state) => {
			state.deleteUserStatus = DataState.LOADING
		})
		builder.addCase(deleteUser.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.deleteUserStatus = DataState.FAILED
			} else {
				state.deleteUserStatus = DataState.SUCCEEDED
				alert('User Deleted');
				localStorage.userId = GetDefaultUser();
				window.location.reload()
			}
		})
		builder.addCase(deleteUser.rejected, (state) => {
			state.deleteUserStatus = DataState.FAILED
		})
		//#endregion

		//#region updateEmail
		builder.addCase(updateEmail.pending, (state, action) => {
			state.emailUpdated = DataState.LOADING
		})
		builder.addCase(updateEmail.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.emailUpdated = DataState.FAILED
			} else {
				if (action.payload.errorCode !== 0) {
					state.userErrorCode = action.payload.errorCode
					state.isError = true;
					state.email = state.oldEmail
				}
				state.emailUpdated = DataState.SUCCEEDED
			}
		})
		builder.addCase(updateEmail.rejected, (state, action) => {
			state.emailUpdated = DataState.FAILED
		})
		//#endregion

		//#region updatePassworrd
		builder.addCase(updatePassword.pending, (state, action) => {
			state.passwordUpdated = DataState.LOADING
		})
		builder.addCase(updatePassword.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.userId === '00000000-0000-0000-0000-000000000000') {
				state.passwordUpdated = DataState.FAILED
				//state.userError = action.payload.errorMessage;
			} else {
				state.passwordUpdated = DataState.SUCCEEDED;
				state.password = '';
				state.oldPassword = '';
				state.confirmPassword = '';
			}
		})
		builder.addCase(updatePassword.rejected, (state, action) => {
			state.passwordUpdated = DataState.FAILED
		})
		//#endregion

		//#region postLegacyTitanTVAccount
		builder.addCase(postLegacyTitanTVAccount.pending, (state, action) => {
			state.oldAccountLoadingStatus = DataState.LOADING
		})
		builder.addCase(postLegacyTitanTVAccount.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.oldAccountLoadingStatus = DataState.FAILED
			} else {
				state.oldTitanAccount = action.payload
				state.oldAccountLoadingStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(postLegacyTitanTVAccount.rejected, (state, action) => {
			state.oldAccountLoadingStatus = DataState.FAILED
		})
		//#endregion

		//#region postLegacyTitanTVMigrate
		builder.addCase(postLegacyTitanTVMigrate.pending, (state, action) => {
			state.oldAccountLoadingStatus = DataState.LOADING
		})
		builder.addCase(postLegacyTitanTVMigrate.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.oldAccountLoadingStatus = DataState.FAILED
				state.accountMigrationStatus = DataState.FAILED
			} else {
				if (action.payload.errorCode === 0) {
					state.oldAccountLoadingStatus = DataState.SUCCEEDED
					state.accountMigrationStatus = DataState.SUCCEEDED
					alert('Transfer complete');
				} else {
					state.userError = action.payload.errorMessage
					state.userErrorCode = action.payload.errorCode
					state.oldAccountLoadingStatus = DataState.FAILED
					state.accountMigrationStatus = DataState.FAILED
				}
			}
		})
		builder.addCase(postLegacyTitanTVMigrate.rejected, (state, action) => {
			state.oldAccountLoadingStatus = DataState.FAILED
			state.accountMigrationStatus = DataState.FAILED
		})
		//#endregion

		//#region usernameRecovery
		builder.addCase(postUsernameRecovery.pending, (state, action) => {
			state.usernameRecoveryStatus = DataState.LOADING
		})
		builder.addCase(postUsernameRecovery.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.errorCode !== 0) {
				state.userErrorCode = action.payload.errorCode;
				state.userError = action.payload.errorMessage;
				state.usernameRecoveryStatus = DataState.FAILED
			} else {
				state.usernameRecoveryStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(postUsernameRecovery.rejected, (state, action) => {
			state.usernameRecoveryStatus = DataState.FAILED
		})
		//#endregion

		//#region passwordResetRequest
		builder.addCase(postPasswordResetRequest.pending, (state, action) => {
			state.oldAccountLoadingStatus = DataState.LOADING
		})
		builder.addCase(postPasswordResetRequest.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.errorCode !== 0) {
				state.userErrorCode = action.payload.errorCode;
				state.userError = action.payload.errorMessage;
				state.passwordResetRequestStatus = DataState.FAILED
			} else {
				state.passwordResetRequestStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(postPasswordResetRequest.rejected, (state, action) => {
			state.passwordResetRequestStatus = DataState.FAILED
		})
		//#endregion

		//#region passwordReset
		builder.addCase(postPasswordReset.pending, (state, action) => {
			state.oldAccountLoadingStatus = DataState.LOADING
		})
		builder.addCase(postPasswordReset.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.errorCode !== 0) {
				state.userErrorCode = action.payload.errorCode;
				state.userError = action.payload.errorMessage;
				state.passwordResetStatus = DataState.FAILED
			} else {
				state.passwordResetStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(postPasswordReset.rejected, (state, action) => {
			state.passwordResetStatus = DataState.FAILED
		})
		//#endregion

	}
});

export const {
	setUserId, setUsername, setEmail, setPassword, setConfirmPassword, setIsAnonymous, setNewUserStatus, setPasswordStatus,
	setRemeberMe, setNewRegUser, setEmailUpdated, setPasswordUpdated, setOldPassword, setPageFirstLoad, setNewGuestUserStatus,
	setTransferPage, setOldTitanPassword, setAddLineupTransferList, setRemoveLineupTransferList, setLineupSelectionEmpty, setPasswordResetRquestStatus,
	setPasswordResetStatus, setUsernameRecoveryStatus, setAccountMigrationStatus, setDeletUserStatus
} = userSlice.actions;

export const selectNewGuestUserStatus = (state: RootState) => state.userSlice.newGuestUserStatus;
export const selectDefaultUserStatus = (state: RootState) => state.userSlice.defaultUserStatus;
export const selectUserInfoStatus = (state: RootState) => state.userSlice.userInfoStatus;
export const selectValidateUserStatus = (state: RootState) => state.userSlice.validateUserStatus;
export const selectNewRegisteredStatus = (state: RootState) => state.userSlice.newRegisteredStatus;
export const selectUpgradeGuestUserStatus = (state: RootState) => state.userSlice.upgradeGuestUserStatus;
export const selectDeleteUserStatus = (state: RootState) => state.userSlice.deleteUserStatus;
export const selectNewUserStatus = (state: RootState) => state.userSlice.newUserStatus;
export const selectPasswordStatus = (state: RootState) => state.userSlice.passwordStatus;
export const selectEmailUpdated = (state: RootState) => state.userSlice.emailUpdated;
export const selectPasswordUpdated = (state: RootState) => state.userSlice.passwordUpdated;

export const selectRememberMe = (state: RootState) => state.userSlice.rememberMe;

export const selectUserId = (state: RootState) => state.userSlice.userId;
export const selectUsername = (state: RootState) => state.userSlice.userName;
export const selectEmail = (state: RootState) => state.userSlice.email;
export const selectOldEmail = (state: RootState) => state.userSlice.oldEmail;
export const selectOldPassword = (state: RootState) => state.userSlice.oldPassword;
export const selectPassword = (state: RootState) => state.userSlice.password;
export const selectConfirmPass = (state: RootState) => state.userSlice.confirmPassword;

export const selectIsAnonymous = (state: RootState) => state.userSlice.isAnonymous;
export const selectIsAdmin = (state: RootState) => state.userSlice.isAdmin;
export const selectNewRegUser = (state: RootState) => state.userSlice.newRegUser;
export const selectUserError = (state: RootState) => state.userSlice.userError;
export const selectUserErrorCode = (state: RootState) => state.userSlice.userErrorCode;
export const selectIsError = (state: RootState) => state.userSlice.isError

export const selectPageFirstLoad = (state: RootState) => state.userSlice.pageFirstLoad;
export const selectTransferPage = (state: RootState) => state.userSlice.transferPage;
export const selectOldTitanPassword = (state: RootState) => state.userSlice.oldTitanPassword;
export const selectLineupTransferList = (state: RootState) => state.userSlice.lineupTransferList
export const selectOldAccountLoadingStatus = (state: RootState) => state.userSlice.oldAccountLoadingStatus;
export const selectOldTitanAccount = (state: RootState) => state.userSlice.oldTitanAccount;
export const selectAccountMigrationStatus = (state: RootState) => state.userSlice.accountMigrationStatus;
export const selectPasswordResetRquestStatus = (state: RootState) => state.userSlice.passwordResetRequestStatus;
export const selectPasswordResetStatus = (state: RootState) => state.userSlice.passwordResetStatus;
export const selectUsernameRecoveryStatus = (state: RootState) => state.userSlice.usernameRecoveryStatus;

export default userSlice.reducer;